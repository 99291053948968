import { FC, useState } from 'react';
import { ArticleResponse } from '../../dto/response/article-response.dto';
import {useGetArticles} from '../../hooks/useGetArticles';
import { ArticleDetail } from './article-detail/article-detail.component';
import {ArticlesListItem} from './articles-list-item/articles-list-item.component';

const ArticlesList: FC = () => {
    const [filter, filterSet] = useState("");
    const [selectedItem, selectedItemSet] = useState<ArticleResponse>();
    const {articles, getNextArticles, loading, refreshArticles} =
      useGetArticles();

      if (loading && false) {
        getNextArticles();
        refreshArticles();
      }
    // console.log(articles);
    return (
      <div
      style={{
        display: "grid",
        gridTemplateColumns: "70% 30%",
        columnGap: "1rem",
      }}>
        <div>
          <input
            value={filter}
            onChange={(e) => filterSet(e.target.value)}
          />
          <table width="100%">
            <thead>
              <tr>
                <th>Image</th>
                <th>Titre</th>
              </tr>
            </thead>
            <tbody>
              {articles
                .filter(article => article.title.toLowerCase().includes(filter.toLowerCase()))
                .slice(0,20)
                .map((article) => (
                  <ArticlesListItem article={article} key={article._id} onSelect={(article) => selectedItemSet(article)}/>
                ))
              }
            </tbody>
          </table>
        </div>
        {selectedItem && (
          <ArticleDetail {...selectedItem}/>
        )}
    </div>
    );
};

export {ArticlesList};
