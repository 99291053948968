import { FC } from 'react';
import { ArticleResponse } from '../../../dto/response/article-response.dto';
import { DateTime } from 'luxon';

const ArticleDetail: FC<ArticleResponse> = ({...selectedItem}) => {
    return (
        <div>
        <img src={selectedItem.openingImageURL} alt="" loading="lazy"/>
        <h2>{selectedItem.title}</h2>
        <h3>{selectedItem.publicationDate && (
            `le {selectedItem.publicationDate}`
            )}par {selectedItem.author}</h3>
        <h4>
            Créé le {DateTime.fromISO(selectedItem.creationDate).toLocaleString(DateTime.DATETIME_MED)} et mis à jour le {DateTime.fromISO(selectedItem.modificationDate).toLocaleString(DateTime.DATETIME_MED)}
        </h4>
        <p>{selectedItem.articleBody}</p>
      </div>
    )
}

export { ArticleDetail };