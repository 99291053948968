import {useEffect, useState} from 'react';
import {ArticleResponse} from '../dto/response/article-response.dto';

const PAGE_SIZE = 10;
export const useGetArticles = () => {
  const [articles, setArticles] = useState<ArticleResponse[]>([]);
  const [articleCount, setArticleCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);

  const getNextArticles = () => {
    if ((articles.length >= articleCount && page !== 0) || loading) {
      return;
    }
    setLoading(true);
    const skip = page * PAGE_SIZE;
    fetch(`https://rm36yzazj4.execute-api.eu-west-3.amazonaws.com/prod/article?limit=${PAGE_SIZE}&skip=${skip}`)
      .then(response => response.json())
      .then(json => {
        if (page === 0) {
          setArticles(json);
        } else {
          setArticles(currentArticles => [...currentArticles, ...json]);
        }
        setPage(page + 1);
        setLoading(false);
      })
      .catch(error => {
        console.error('Problem to fetch articles: ' + error.message);
        throw error;
      });
  };

  // fetch count
  const updateArticleCount = () => {
    return fetch('https://rm36yzazj4.execute-api.eu-west-3.amazonaws.com/prod/article/count')
      .then(num => setArticleCount(parseInt(num.toString(), 10)))
      .catch(error => {
        console.error('Problem to fetch count of articles: ' + error.message);
        throw error;
      });
  };

  const refreshArticles = () => {
    setPage(0);
    setArticleCount(0);
    updateArticleCount();
  };

  useEffect(() => {
    updateArticleCount();
  }, []);

  useEffect(() => {
    if (articleCount !== 0) {
      getNextArticles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleCount]);

  return {
    articles,
    getNextArticles,
    refreshArticles,
    loading,
  };
};
