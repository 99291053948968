import { ArticleResponse } from "../../../dto/response/article-response.dto";

interface ArticlesListItemProps {
  article: ArticleResponse
  onSelect: (article: ArticleResponse) => void;
}

const ArticlesListItem: React.FC<ArticlesListItemProps> = ({article, onSelect}) => {
    return (
    <tr>
    <td>
      <img src={article.openingImageURL} alt="" loading="lazy"/>
    </td>
    <td>
      {article.surtitle}
      <h2>{article.title}</h2>
      <h3>{article.publicationDate && (
            `le {selectedItem.publicationDate}`
            )}par {article.author}</h3>
      <p>{article.articleBody}</p>
      {/* <p>{article.tags.join(" - ")}</p> */}
    </td>
    <td>
        <button onClick={() => onSelect(article)}>Select!</button>
    </td>
    </tr>
    )
  }

  export {ArticlesListItem};
