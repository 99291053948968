import { FC } from 'react';

import './tickers.component.css';


const Tickers: FC = () => {
    return (
        <p className="tickers">
            CAC 40 <strong>6603.09</strong> +1.05% -
            DOW JONES <strong>34911.2</strong> +1.43% -
            NASDAQ <strong>13619.66</strong> +2.15% -
            NIKKEI <strong>27217.85</strong> +0.87%
        </p>
    )
}

export {Tickers};