import './App.css';
import { ArticlesList } from './components/articles-list/articles-list.component';
import { Tickers } from './components/tickers/tickers.component';

function App() {
  return (
  <div
      style={{
        margin: "0 3rem",
        // width: 640,
        paddingTop: "1rem",
      }}>
    <Tickers/>
    <h1 className="title">L'Echo du Web</h1>
    <ArticlesList/>
  </div>
  );
}

export default App;
